html, body, #root, .app-container, .h100 {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
}

body {
  background-color: white;
  color:white;
}

input:focus {
  outline: none;
}

button.app-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}


div.message-container {
  display: flex;
  flex-direction: column;
  background-color: #191919;
}


/* Message Input Container */
div.message-header {
  padding: 10px 10px 10px 10px;
}
div.message-header .profile-circle-col {
  flex-grow:0;
  flex-shrink:0;
}
div.message-header .profile-circle {
  padding: 5px;
  width: 38px;
  border-radius: 40px;
  background-color: grey;
  font-size: 19px;
  text-align: center;
}

div.message-header .title {
  margin-left: 15px;
  margin-top: 7px;
  font-weight: bold;
}

div.message-pane {
  flex-basis: 100%;
  flex: 1 1 auto;
  padding: 20px;
  overflow-x:hidden; 
  overflow-y:auto;

  flex-direction: column-reverse;
  overflow-anchor: none;
}
div.message-pane::-webkit-scrollbar {
  width: 6px;
  background-color: black;
  outline: 0px solid slategrey;
  border-radius:10px;
}
div.message-pane::-webkit-scrollbar-thumb {
  background-color:#4b4b4b;
  border-radius:10px;
  padding:3px;
}

div.message-row {
  margin:7px 0px;
  display:flex;
}
div.message-row div.message {
  font-size: 14px;
  padding:5px 5px;
  background-color:#4b4b4b;

  display: flex;
  flex-grow: 0;
  flex-basis: fit-content;
  max-width: 80%;
  border-radius:5px;
}
div.message-row div.own-message {
  margin-left: auto;
  display:flex;
  align-content: flex-end;
  background-color:#5b5bff;
}

div.compose-message {
  height:50px;
  flex-grow:0;
  flex-shrink:0;
  display: flex;
}

div.compose-message div.message-input-col {
  padding: 0px 20px;
}

input.message-input {
  width:100%;
  background-color:#2d3233;
  color:white;
  border-radius: 4px;
  border:1px solid #4d4d4d;
  padding: 0px 7px;
  height: 30px;
  font-size:14px;
}

div.message-submit-col {
  flex-basis: 50px;
  flex-grow:0;
  flex-shrink:0;
}

.airplane-icon {
  height:21px;
  margin-top:5px;
}

/* Contacts Container */

div.contacts-container {
  background-color: #2d3233;
  display: flex;
  flex-direction: column;

  flex-grow: 0;     /* do not grow   - initial value: 0 */
  flex-shrink: 0;   /* do not shrink - initial value: 1 */
  flex-basis: 320px; /* width/height  - initial value: auto */
  border-right: 1px solid #4d4d4d;
}

div.contacts-list {
  overflow-x:hidden; 
  overflow-y:auto;

/*  overflow-anchor: none;*/
}
div.contacts-list::-webkit-scrollbar {
  width: 6px;
  background-color: black;
  outline: 0px solid slategrey;
  border-radius:10px;
}
div.contacts-list::-webkit-scrollbar-thumb {
  background-color:#4b4b4b;
  border-radius:10px;
  padding:3px;
}

div.new-recipient-container {
  display: flex;
  padding:10px;
  margin-bottom: 5px;
}

div.profile-circle-col {
  flex-grow:0;
  flex-shrink:0;
}

div.profile-circle {
  padding: 5px;
  border-radius: 40px;
  background-color: grey;
  font-size: 19px;
  text-align: center;
  width:38px;
}

div.new-message-col {
  flex-grow:0;
  flex-shrink:0;
  flex-basis:49px;
  text-align: center;
}

.compose-icon {
  height:25px;
}

.compose-btn {
  margin-top: 7px;
}

div.back-btn-col {
  flex-grow:0;
  flex-shrink:0;
  flex-basis:35px;
}

div.new-conversation-text {
  align-self:flex-end;
  padding-bottom:3px;
}

div.search-contact {
  padding: 0px 10px;
  flex-grow:0;
  flex-shrink:0;
  display: flex;
}

input.search-contact-input {
  width:100%;
  background-color:#333d3f;
  color:white;
  border-radius: 4px;
  border:1px solid #4d4d4d;
  padding: 0px 7px;
  height: 30px;
  font-size:14px;
}

button.send-invite-btn {
  margin-top:10px;
  font-size:14px;
  border: 1px solid #4d4d4d;
  border-radius: 3px;
  padding:5px;
  color:grey;
  color:#989898;
}
span.username-highlight {
  color:white;
}

/* Event list */
div.event-tile {
  display: flex;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

div.event-tile:hover {
  background-color: #3d3d3d;
  cursor:pointer;
}
div.event-tile.active {
  background-color: #4b4b4b;
}


div.event-profile-circle-col {
  flex-grow:0;
  flex-shrink:0;
  flex-basis:49px;
}

div.event-profile-circle {
  padding: 10px;
  border-radius: 40px;
  background-color: grey;
  font-size: 19px;
  text-align: center;
}

div.event-meta-col {
  flex-grow:0;
  flex-shrink:0;
  flex-basis:25px;
  padding: 12px 0px px 0px;
  text-align: center;
}

div.event-tile div.event-summary-col {
  padding-left:15px;
}
div.username {
  font-weight:bold;
}
div.summary-text {
  font-size: 14px;
  color: #a7a7a7;
}
span.invite-sent-text {

}
.accept-icon {
  color: white;
  font-size:20px;
  margin-left: 7px;
}

.sign-in-page {
  background-color: #2d3233;
  display: flex;
flex-direction: column;
  border-right: 1px solid #4d4d4d;
  align-items: center;
}

.sign-in-wrapper {
  display: flex;

    align-items: center;
}

@media only screen and (max-width: 600px) {
  .contacts-container {
    background-color: lightblue;
  }
}

/* Sign In */

.btn-flat {
  margin-top:15px;
  background-color: #007c59;
  color: white;
  border:1px solid #06a07a;
}
.btn-flat:hover{
  color:white;
  border: 1px solid #05a03e;
}
.btn-xxl {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

input.sign-in-field {
  width:100%;
}
input.password {
  margin-top:10px;
}
